import React from "react";

const DashBoard = React.lazy(() => import("./pages/Dashboard"));
const Registration = React.lazy(() => import("./pages/Registration"));
const WorkingDays = React.lazy(() => import("./pages/Working-Days"));
const LeaveDays = React.lazy(() => import("./pages/Leave-Days"));
const Roaster = React.lazy(() => import("./pages/Roaster"));
const DTR = React.lazy(() => import("./pages/DTR"));
const PayRoll = React.lazy(() => import("./pages/Payroll"));
const Revenue = React.lazy(() => import("./pages/Revenue"));
const PNL = React.lazy(() => import("./pages/PNL"));
const FTE = React.lazy(() => import("./pages/FTE"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Attendance = React.lazy(() => import("./pages/Attendance"));
const Messages = React.lazy(() => import("./pages/Chats"));
const Passwords = React.lazy(() => import("./pages/Passwords"));
const NotFound = React.lazy(() => import("./pages/404page"));
const Users = React.lazy(() => import("./pages/User"));
const NAT = React.lazy(() => import("./pages/NAT"));
const PR = React.lazy(() => import("./pages/PR"));
const SA = React.lazy(() => import("./pages/Session-Approvals"));
const Sessions = React.lazy(() => import("./pages/Sessions"));
const Utilytics = React.lazy(() => import("./pages/Utilytics"));
// const Fleet = React.lazy(() => import("./pages/Fleet"));
// const VehicleAllocation = React.lazy(() => import("./pages/VehicleAllocation"));
// const Fuel = React.lazy(() => import("./pages/Fuel"));
// const Maintenance = React.lazy(() => import("./pages/Maintenance"));
// const ExpenseOverview = React.lazy(() => import("./pages/ExpenseOverview"));
// const Accident = React.lazy(() => import("./pages/Accident"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", permission: 'Dashboard' ,element: DashBoard },
  { path: "/registration", name: "Registration", element: Registration },
  { path: "/working-days", name: "WorkingDays", element: WorkingDays },
  { path: "/leave-days", name: "LeaveDays", element: LeaveDays },
  { path: "/roaster", name: "Roaster", element: Roaster },
  { path: "/nat", name: "NAT" ,element: NAT },
  { path: "/pr", name: "PR" ,element: PR },
  { path: "/sa", name: "SA" ,element: SA },
  { path: "/sessions", name: "Sessions" ,element: Sessions },
  { path: "/utilization", name: "Utilytics" ,element: Utilytics },
  { path: "/users", name: "Users", element: Users },
  // { path: "/manage-fleet", name: "Manage Fleet",element: Fleet },
  // { path: "/fuel", name: "Fuel",element: Fuel },
  // { path: "/maintenance", name: "Maintenance",element: Maintenance },
  // { path: "/expense", name: "Expense Overview",element: ExpenseOverview },
  // { path: "/vehicle-allocation", name: "Vehicle Allocation",element: VehicleAllocation },
  // { path: "/accident", name: "Vehicle Allocation",element: Accident },
  { path: "/dtr", name: "DTR", element: DTR },
  { path: "/payroll", name: "PayRoll", permission: 'PayRoll' ,element: PayRoll },
  { path: "/revenue", name: "Revenue", permission: 'Revenue' ,element: Revenue },
  { path: "/pnl", name: "PNL", permission: 'PNL' ,element: PNL },
  { path: "/fte", name: "FTE", element: FTE },
  { path: "/settings", name: "Settings", permission: 'Settings' ,element: Settings },
  { path: "/attendance", name: "Attendance", element: Attendance },
  { path: "/messages", name: "Messages", element: Messages },
  { path: "/passwords", name: "Passwords" ,element: Passwords },
  { path: "*", name: "NotFound" ,element: NotFound },
];

export default routes;
